import { Select as ReactSelect, chakraComponents } from "chakra-react-select"
import { Flex, Image, Text } from "@chakra-ui/react"

import { useState, useCallback, useEffect, useMemo } from "react"
import selectArrowDown from "../img/select_arrow_down.svg"
import { debounce } from "lodash"
import { CITY, COLORS } from "../constants/constants"

export const selectChakraStyles = {
  control: (provided, state) => {
    const {
      selectProps: { overrideStyles = { control: {}, menu: {} } },
    } = state

    return {
      ...provided,
      _focusVisible: {
        ...provided._focusVisible,
        boxShadow: "none",
      },
      borderRadius: 12,
      zIndex: "0!important",
      ...overrideStyles.control,
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    background: "none",
    paddingInlineStart: "12px",
    paddingInlineEnd: "12px",
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: 12,
  }),
  menu: (provided) => ({
    ...provided,
  }),
}

const ValueContainer = ({ children, labelLeftText, ...rest }) => (
  <chakraComponents.ValueContainer {...rest}>
    <Flex align={"center"} m={0} p={0}>
      {typeof labelLeftText === "string" ? (
        <Text mr={1} color={"#6A6E85"}>
          {labelLeftText}
        </Text>
      ) : (
        labelLeftText
      )}
      <Flex w={"100%"} position={"relative"} align={"center"} m={0} p={0}>
        {children}
      </Flex>
    </Flex>
  </chakraComponents.ValueContainer>
)

const CustomDropdownIndicator = (value) => {
  return (
    <Image
      src={selectArrowDown}
      px={2}
      alt={`expand_to_reveal_reward_flights_to_${
        value?.label || "destination"
      }`}
    />
  )
}

export const Select = ({
  labelLeftText = "",
  options,
  isSearchable = false,
  focused = false,
  showArrow = true,
  withCheckbox = false,
  singleValueStyles = {},
  isDisabled = false,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("")
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue)
  const showSearchOptions = inputValue.length > 2
  const searchable = isSearchable
    ? {
        inputValue,
        onInputChange: (value, { action }) =>
          action === "input-change" ? setInputValue(value) : null,
      }
    : {}

  const Container = useCallback(
    (props) => <ValueContainer {...props} labelLeftText={labelLeftText} />,
    [labelLeftText]
  )

  useEffect(() => {
    const handler = debounce(() => setDebouncedInputValue(inputValue), 300)
    handler()
    return () => handler.cancel()
  }, [inputValue])

  const filteredOptions = useMemo(() => {
    if (!isSearchable) return options

    if (debouncedInputValue.length > 2) {
      const currentAirport = options.find(
        (o) => o.value.toLowerCase() === debouncedInputValue.toLowerCase()
      )

      return options.filter(
        (o) =>
          o.searchBy
            .toLowerCase()
            .includes(debouncedInputValue.toLowerCase()) ||
          o.city === currentAirport?.city
      )
    } else {
      return []
    }
  }, [debouncedInputValue, isSearchable, options])

  const sortedOptions = filteredOptions.sort((a, b) => {
    if (a.type === CITY && b.type !== CITY) {
      return -1
    }
    if (a.type !== CITY && b.type === CITY) {
      return 1
    }
    return 0
  })

  const customStyles = {
    ...selectChakraStyles,
    menu: (base) => ({
      ...base,
      display: isSearchable && !showSearchOptions ? "none" : base.display,
      zIndex: 999,
    }),
    control: (base) => ({
      ...base,
      _focusVisible: {
        ...base._focusVisible,
        boxShadow: "none",
      },
      borderRadius: 12,
      zIndex: "0!important",
      paddingRight: showArrow ? "0px" : "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused
        ? `${COLORS.red}!important`
        : `${COLORS.black}!important`,
      backgroundColor: state.isFocused
        ? `${COLORS.grey}!important`
        : `${COLORS.white}!important`,
      fontSize: singleValueStyles.fontSize || "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: singleValueStyles.fontSize || "16px",
    }),
    valueContainer: (base) => ({
      ...base,
      fontWeight: 600,
      fontSize: singleValueStyles.fontSize || "16px",
      paddingInlineStart: singleValueStyles.paddingInlineStart || "12px",
      paddingInlineEnd: 0,
      input: { height: "auto" },
      "& > div > div > div:last-of-type": {
        position: "absolute",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginInlineStart: "0!important",
        marginInlineEnd: 0,
        "& > input": {
          fontWeight: 600,
          opacity: 1,
        },
      },
    }),
  }

  const altText = props.value?.label
    ? props.value?.label === "One way" || props.value?.label === "Round trip"
      ? "select_number_of_passengers"
      : `expand_to_reveal_reward_flights_to_${props.value?.label}`
    : "destination"

  return (
    <ReactSelect
      menuPlacement="auto"
      isOptionDisabled={() => isDisabled}
      focusBorderColor="black"
      autoFocus={focused}
      isSearchable={isSearchable}
      options={sortedOptions}
      filterOption={() => true}
      components={{
        ValueContainer: Container,
        DropdownIndicator: showArrow
          ? () => CustomDropdownIndicator(altText)
          : null,
        IndicatorSeparator: null,
      }}
      {...searchable}
      {...props}
      onFocus={() => setInputValue("")}
      onBlur={() => setInputValue("")}
      onChange={(value) => {
        setInputValue("")
        props.onChange(value)
      }}
      chakraStyles={customStyles}
    />
  )
}
