import { tableAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers, extendTheme } from "@chakra-ui/react"
import { COLORS } from "./constants/constants"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const variantRounded = definePartsStyle({
  table: {
    borderCollapse: "separate",
    borderSpacing: 0,
    borderRadius: "12px",
  },
  th: {
    "&:first-of-type": {
      borderTopLeftRadius: "12px",
    },
    "&:last-of-type": {
      borderTopRightRadius: "12px",
    },
  },
  td: {
    "&:first-of-type": {
      borderBottomLeftRadius: "12px",
    },
    "&:last-of-type": {
      borderBottomRightRadius: "12px",
    },
  },
})

export const tableTheme = defineMultiStyleConfig({
  variants: { rounded: variantRounded },
  defaultProps: {
    variant: "rounded",
  },
})

const theme = extendTheme({
  components: {
    Table: tableTheme,
    Input: {
      sizes: {
        md: {
          field: {
            borderRadius: 12,
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 12,
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: 4,
          borderWidth: 1,
          boxShadow: "none!important",
          _hover: {
            borderColor: COLORS.red,
          },
          _checked: {
            bg: COLORS.white,
            borderColor: COLORS.red,
            color: COLORS.red,
            _hover: {
              bg: COLORS.white,
            },
          },
          icon: {
            color: COLORS.red,
          },
        },
      },
    },
  },
  fonts: {
    heading: `'Nunito Sans', sans-serif`,
    body: `'Nunito Sans', sans-serif`,
  },
  styles: {
    global: {
      body: {
        minHeight: "100vh",
      },
      ".datepicker-wrapper": {
        background: "#fff",
        borderRadius: 12,
        boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.15)",

        "& > div:first-of-type": {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        },
      },
      ".alert-wrapper .react-datepicker .react-datepicker__day": {
        color: "black",
      },
      ".react-datepicker-wrapper": {
        width: "100%",
      },
      ".react-datepicker-popper": {
        zIndex: 3,
      },
      ".react-datepicker__day--in-selecting-range": {
        backgroundColor: "#EEEEEE!important",
      },
      ".react-datepicker__month .react-datepicker__day--in-range": {
        backgroundColor: "#EEEEEE",
      },
      ".alert-wrapper .react-datepicker .react-datepicker__day--disabled": {
        color: "#B3B4B9",
      },
      ".alert-wrapper .react-datepicker__day.react-datepicker__day--in-range": {
        color: "white",
        borderRadius: "50%",
        backgroundColor: "#6A6E85",
      },
      ".alert-wrapper .react-datepicker__day.react-datepicker__day--in-selecting-range":
        {
          color: "white",
          borderRadius: "50%",
          backgroundColor: "#6A6E85",
        },
      ".alert-wrapper .react-datepicker__day:hover": {
        borderRadius: "50%",
      },
      ".react-datepicker__day--outside-month": {
        opacity: 0,
      },
      ".react-datepicker": {
        border: "none",
        ".react-datepicker__header": {
          borderBottom: 0,
          backgroundColor: COLORS.grey,
          paddingTop: "20px",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        },
        ".react-datepicker__day": {
          width: "45px",
          lineHeight: "45px",
          color: "#B3B4B9",
        },
        ".react-datepicker__day-name": {
          width: "45px",
          color: "#6A6E85",
          fontWeight: "bold",
          fontSize: "12px",
        },
        ".react-datepicker__day--selected": {
          // backgroundColor: '#DD0000',
          backgroundColor: "transparent",
          borderRadius: "50%",
          // color: '#FFFFFF;',
        },
        ".react-datepicker__current-month": {
          color: "#141725",
          marginBottom: "10px",
        },
        ".react-datepicker__navigation": {
          top: "20px",
        },
        ".react-datepicker__navigation-icon::before": {
          height: "7px",
          width: "7px",
          borderWidth: "2px 2px 0 0",
          borderColor: "#6A6E85",
        },
        ".color-wheel": {
          borderRadius: "50%",
          color: "#141725",
        },
        ".react-datepicker__day--selected .color-wheel": {
          color: "white",
        },
        ".color-wheel::after": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          borderRadius: "50%",
          background: "white",
          width: "70%",
          height: "70%",
          transform: "translate(-50%, -50%)",
        },
        ".react-datepicker__day--selected .color-wheel::after": {
          background: "#6A6E85;",
        },
      },
      ".chakra-popover__popper": {
        zIndex: "20!important",
      },
      "@media (max-width: 767px)": {
        ".alerts-date-picker-wrapper .react-datepicker-popper": {
          height: "max-content!important",
          borderRadius: "12px",
          paddingBottom: "0",
        },
        ".react-datepicker": {
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          ".react-datepicker__header": {
            borderRadius: "12px",
            margin: "12px 12px 0",
          },
        },
      },
      "@media (max-width: 400px)": {
        ".react-datepicker": {
          ".react-datepicker__day": {
            width: "40px",
          },
          ".react-datepicker__day-name": {
            width: "40px",
          },
          ".color-wheel": {
            height: "40px!important",
          },
        },
      },
    },
  },
})

export default theme
