import {
  Text,
  Stack,
  Image,
  Flex,
  Show,
  Button,
  Box,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "@tanstack/react-query"
import { Link as RouterLink, useLocation } from "react-router-dom"

import { ReactComponent as Logo } from "../img/gyoza_logo.svg"
import { ReactComponent as AllRewardsMenuIcon } from "../img/all_rewards_menu_icon.svg"
import { ReactComponent as AustralianFlightsMenuIcon } from "../img/australian_flights_menu_icon.svg"
import { ReactComponent as AlertMenuIcon } from "../img/alert_menu_icon.svg"
import { ReactComponent as SettingsMenuIcon } from "../img/settings_menu_icon.svg"
import { ReactComponent as LogoutMenuIcon } from "../img/logout_menu_icon.svg"
import { ReactComponent as PriceMenuIcon } from "../img/price_menu_icon.svg"
import { ReactComponent as AmadeusMenuIcon } from "../img/amadeus_menu_icon.svg"
// import { ReactComponent as QantasHourlyMenuIcon } from "../img/qantas_favicon_menu.svg"
import { ReactComponent as ClosedNavbarLogo } from "../img/closed_navbar_logo.svg"
import { logout } from "../services/api"
import { useAuthContext } from "../services/auth"
import { COLORS } from "../constants/constants"

const MenuItem = ({ children, isActive, isOpen, isUserPresent, ...props }) => {
  return (
    <Flex
      as={RouterLink}
      cursor="pointer"
      width={[isUserPresent ? "32px" : "44px", "44px", "44px", "100%"]}
      alignItems="center"
      bg={isActive ? COLORS.grey : "none"}
      p={[isUserPresent ? "6px" : 3, 3, 3, isOpen ? 3 : 4]}
      height={{ base: "44px", lg: "100%" }}
      borderRadius={12}
      fontSize={14}
      color={isActive ? COLORS.red : "#6A6E85"}
      gap={3}
      justifyContent={isOpen ? "flex-start" : "center"}
      {...props}
    >
      {children}
    </Flex>
  )
}

const Menu = ({ isOpen, handleToggleMenu }) => {
  const mt = useBreakpointValue({ base: 0, lg: isOpen ? 0 : 26 })
  const mb = useBreakpointValue({ base: 0, lg: isOpen ? 24 : 34 })
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const queryClient = useQueryClient()
  const location = useLocation()

  const { t } = useTranslation()
  const { user } = useAuthContext()

  const handleOnLogout = () => {
    logout()
    queryClient.resetQueries()
  }

  const isUserPresent = !!user
  const showLoginButton = !isUserPresent && location.pathname !== "/login"

  return (
    <Stack
      direction={{ base: "row", lg: "column" }}
      justifyContent={{ base: "space-between", lg: "flex-start" }}
      bg="white"
      flexBasis={{ base: 0, lg: `${isOpen ? "260px" : "88px"}` }}
      flexShrink={0}
      px={4}
      py={{ base: 4, lg: isOpen ? 7 : 8 }}
      boxShadow={{
        base: "0px 2px 8px rgba(20, 23, 37, 0.08)",
        lg: "10px 0 12px -5px rgba(20, 23, 37, 6%)",
      }}
      alignItems={{ base: "center", lg: isOpen ? "flex-start" : "center" }}
      position={"relative"}
      zIndex={1}
      width={"100%"}
      spacing={0}
      gap={{ base: 3, lg: 0 }}
    >
      <Flex
        display={{ base: "none", lg: "flex" }}
        zIndex={1}
        border={"1px solid #F1F1F4"}
        borderRadius={8}
      >
        <button
          onClick={handleToggleMenu}
          style={{
            width: "28px",
            height: "28px",
            backgroundColor: COLORS.grey,
            position: isOpen ? "absolute" : "static",
            top: "30px",
            right: "-14px",
            borderRadius: "8px",
            padding: "0",
          }}
        >
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="11"
              viewBox="0 0 7 11"
              fill="#6A6E85"
              style={{ marginLeft: "9px" }}
            >
              <path d="M6.84101 8.85768L3.02435 5.04102L6.84101 1.21602L5.66602 0.0410156L0.666016 5.04102L5.66602 10.041L6.84101 8.85768Z" />
            </svg>
          ) : (
            <svg
              width="7"
              height="11"
              viewBox="0 0 7 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginLeft: "11px" }}
            >
              <path
                d="M0.158984 1.22435L3.97565 5.04102L0.158985 8.86602L1.33398 10.041L6.33398 5.04102L1.33398 0.0410161L0.158984 1.22435Z"
                fill="#6A6E85"
              />
            </svg>
          )}
        </button>
      </Flex>

      <Box
        as={RouterLink}
        to="/"
        style={{ marginTop: mt, marginBottom: mb }}
        flex={"0 1 auto"}
      >
        {isOpen || !isDesktop ? (
          <Image
            as={Logo}
            width={"100%"}
            height={{ base: 9, lg: "49px" }}
            display={"block"}
            alt="gyoza-reward-flights"
          />
        ) : (
          <Image
            as={ClosedNavbarLogo}
            display={"block"}
            width={"100%"}
            alt="gyoza-reward-flights"
          />
        )}
      </Box>

      <Flex
        flexDirection={{ base: "row", lg: "column" }}
        gap={{ base: 2, md: 4, lg: isOpen ? 8 : 1 }}
        alignItems={{ base: "center", lg: "flex-start" }}
      >
        <Flex flexDirection={{ base: "row", lg: "column" }} gap={1}>
          <MenuItem
            to={"/"}
            isActive={
              location.pathname === "/" ||
              location.pathname.includes("/reward-flights/")
            }
            isOpen={isOpen}
            isUserPresent={isUserPresent}
          >
            <Image
              as={AllRewardsMenuIcon}
              width={{ base: "100%", lg: isOpen ? 5 : 6 }}
              height={{ base: "100%", lg: isOpen ? 5 : 6 }}
              display="inline"
              fill={
                location.pathname === "/" ||
                location.pathname.includes("/reward-flights/")
                  ? "#DD0000"
                  : "#6A6E85"
              }
            />
            <Show above="lg">
              <Text as={"h3"} display={isOpen ? "block" : "none"}>
                {t("allReward.navbarTitle")}
              </Text>
            </Show>
          </MenuItem>

          {/* <MenuItem
            to={"/qantas-hourly-alerts"}
            ml={0}
            isActive={location.pathname.includes("/qantas-hourly-alerts")}
            isOpen={isOpen}
            isUserPresent={isUserPresent}
          >
            <Image
              as={QantasHourlyMenuIcon}
              width={{ base: "100%", lg: isOpen ? 5 : 6 }}
              height={{ base: "100%", lg: isOpen ? 5 : 6 }}
              display="inline"
              stroke={
                location.pathname.includes("/qantas-hourly-alerts")
                  ? "#DD0000"
                  : "#6A6E85"
              }
              fill={
                location.pathname.includes("/qantas-hourly-alerts")
                  ? "#DD0000"
                  : "#6A6E85"
              }
            />
            <Show above="lg">
              <Text as={"h3"} display={isOpen ? "block" : "none"}>
                {t("mapView.title")}
              </Text>
            </Show>
          </MenuItem> */}

          <MenuItem
            to={"/all-reward-flights"}
            ml={0}
            isActive={location.pathname.startsWith("/all-reward-flights")}
            isOpen={isOpen}
            isUserPresent={isUserPresent}
          >
            <Image
              as={AustralianFlightsMenuIcon}
              width={{ base: "100%", lg: isOpen ? 5 : 6 }}
              height={{ base: "100%", lg: isOpen ? 5 : 6 }}
              display="inline"
              stroke={
                location.pathname.startsWith("/all-reward-flights")
                  ? "#DD0000"
                  : "#6A6E85"
              }
            />
            <Show above="lg">
              <Text as={"h3"} display={isOpen ? "block" : "none"}>
                {t("allBusinessFirstClasses")}
              </Text>
            </Show>
          </MenuItem>

          <MenuItem
            to={"/flight-availability"}
            ml={0}
            isActive={location.pathname.includes("/flight-availability")}
            isOpen={isOpen}
            isUserPresent={isUserPresent}
          >
            <Image
              as={AmadeusMenuIcon}
              width={{ base: "100%", lg: isOpen ? 5 : 6 }}
              height={{ base: "100%", lg: isOpen ? 5 : 6 }}
              display="inline"
              stroke={
                location.pathname.includes("/flight-availability")
                  ? "#DD0000"
                  : "#6A6E85"
              }
            />
            <Show above="lg">
              <Text as={"h3"} display={isOpen ? "block" : "none"}>
                {t("amadeus.navbarTitle")}
              </Text>
            </Show>
          </MenuItem>

          <MenuItem
            to={"/pricing"}
            ml={0}
            isActive={location.pathname === "/pricing"}
            isOpen={isOpen}
            isUserPresent={isUserPresent}
          >
            <Image
              as={PriceMenuIcon}
              width={{ base: "100%", lg: isOpen ? 5 : 6 }}
              height={{ base: "100%", lg: isOpen ? 5 : 6 }}
              display="inline"
              stroke={location.pathname === "/pricing" ? "#DD0000" : "#6A6E85"}
            />
            <Show above="lg">
              <Text as={"h3"} display={isOpen ? "block" : "none"}>
                {t("prices")}
              </Text>
            </Show>
          </MenuItem>

          {isUserPresent && (
            <>
              <MenuItem
                to={"/settings"}
                ml={0}
                isActive={location.pathname === "/settings"}
                isOpen={isOpen}
                isUserPresent={isUserPresent}
              >
                <Image
                  as={SettingsMenuIcon}
                  width={{ base: "100%", lg: isOpen ? 5 : 6 }}
                  height={{ base: "100%", lg: isOpen ? 5 : 6 }}
                  display="inline"
                  stroke={
                    location.pathname === "/settings" ? "#DD0000" : "#6A6E85"
                  }
                />
                <Show above="lg">
                  <Text as={"h3"} display={isOpen ? "block" : "none"}>
                    {t("settings.title")}
                  </Text>
                </Show>
              </MenuItem>

              <MenuItem
                to={"/alerts"}
                ml={0}
                isActive={location.pathname === "/alerts"}
                isOpen={isOpen}
                isUserPresent={isUserPresent}
              >
                <Image
                  as={AlertMenuIcon}
                  width={{ base: "100%", lg: isOpen ? 5 : 6 }}
                  height={{ base: "100%", lg: isOpen ? 5 : 6 }}
                  display="inline"
                  stroke={
                    location.pathname === "/alerts" ? "#DD0000" : "#6A6E85"
                  }
                />
                <Show above="lg">
                  <Text as={"h3"} display={isOpen ? "block" : "none"}>
                    {t("alerts.header")}
                  </Text>
                </Show>
              </MenuItem>

              <MenuItem
                ml={0}
                onClick={handleOnLogout}
                isOpen={isOpen}
                isUserPresent={isUserPresent}
              >
                <Image
                  as={LogoutMenuIcon}
                  width={{ base: "100%", lg: isOpen ? 5 : 6 }}
                  height={{ base: "100%", lg: isOpen ? 5 : 6 }}
                  display="inline"
                  stroke={"#6A6E85"}
                />
                <Show above="lg">
                  <Text as={"h3"} display={isOpen ? "block" : "none"}>
                    {t("logout")}
                  </Text>
                </Show>
              </MenuItem>
            </>
          )}
        </Flex>

        {showLoginButton && (
          <Box w={{ lg: "100%" }}>
            <RouterLink to="/login">
              <Button
                backgroundColor={COLORS.red}
                color="white"
                w={{ base: "44px", lg: isOpen ? "100%" : 12 }}
                h={{ base: "44px", lg: isOpen ? "auto" : 12 }}
                padding={{ base: 3, lg: 4 }}
              >
                {isOpen && isDesktop ? (
                  <Heading
                    as={"h3"}
                    textTransform={"uppercase"}
                    fontSize={{ base: "12px", lg: "16px" }}
                  >
                    login
                  </Heading>
                ) : (
                  <Image
                    as={LogoutMenuIcon}
                    width={{ base: 5, lg: 6 }}
                    height={{ base: 5, lg: 6 }}
                    stroke={"#fff"}
                  />
                )}
              </Button>
            </RouterLink>
          </Box>
        )}
      </Flex>
    </Stack>
  )
}

export default Menu
