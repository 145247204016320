/* eslint-disable no-control-regex */

import { getDatesOptions } from "../helpers/functions"

export const DATES_OPTIONS = getDatesOptions()

export const ANY = "Any"
export const CITY = "city"

export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const AIRPLANE_COLORS = {
  Economy: "#DD0F0F",
  PremiumEconomy: "#FC9E0A",
  Business: "#B39E69",
  First: "#796F5A",
}

export const COLORS = {
  white: "#FFFFFF",
  grey: "#F7F7F9",
  secondary: "#6A6E85",
  black: "#141725",
  red: "#DD0000",
  green: "#59B72A",
}

export const TRAFFIC_LIGHT_COLORS = {
  Red: "#DD0000",
  Amber: "#FFD700",
  Green: "#00FF00",
}

export const PRICE_INTERVAL = {
  MONTH: "month",
  YEAR: "year",
}

export const SUBSCRIPTION = {
  FREE: "free",
  MONTHLY_LITE: "monthly_lite",
  MONTHLY_MAX: "monthly_max",
  ANNUAL: "annual",
  ADMIN: "admin",
}

export const flightClassesMapping = {
  Economy: "economy",
  PremiumEconomy: "premiumEconomy",
  Business: "business",
  First: "first",
  Unmapped: "unmapped",
}

export const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss"

export const DATE_FORMAT_EXPANDABLE_ROW = "EEEE, MMMM dd, HH:mm aa"
// 'yyyy-MM-dd HH:mm:ss'

export const DATE_FORMAT_AUSTRALIA_DEPART = "dd MMM yyyy"
export const DATE_FORMAT_LIKELIHOOD_POPOVER = "dd MMM yyyy, HH:mm"
export const CHART_TOOLTIP_DATE = "EEE, MMM d"

export const maxAlertsPerSubscription = {
  FREE: 0,
  ADMIN: 99999,
  TESTING: 5,
  MONTHLY_LITE: 5,
  MONTHLY_MAX: 100,
  ANNUAL: 99999,
}

export const ITEMS_PER_PAGE = 10
export const ITEMS_PER_PAGE_AUSTRALIA = 15

export const flightClassesColors = {
  Economy: "#DD0000",
  PremiumEconomy: "#FC9E0A",
  Business: "#B39E69",
  First: "#796F5A",
}

export const defaultFlightsTexts = {
  from: {
    id: 38,
    code: "SFO",
    name: "San Francisco Intl",
    city: "San Francisco",
    fullName: "San Francisco Intl (SFO)",
  },
  to: {
    id: 40,
    code: "SYD",
    name: "Sydney Kingsford Smith Intl",
    city: "Sydney",
    fullName: "Sydney Kingsford Smith Intl (SYD)",
  },
}

export const defaultTop10Airports = [
  { name: "Barcelona", code: "BCN" },
  { name: "Rome", code: "FCO" },
  { name: "Hong Kong", code: "HKG" },
  { name: "London (Heathrow)", code: "LHR" },
  { name: "Melbourne", code: "MEL" },
  { name: "Singapore", code: "SIN" },
  { name: "Sydney", code: "SYD" },
  { name: "Beijing", code: "PEK" },
  { name: "Osaka", code: "KIX" },
  { name: "Tokyo (Haneda)", code: "HND" },
]

export const top100AirportCodes = [
  "ATL",
  "PEK",
  "DXB",
  "LAX",
  "ORD",
  "LHR",
  "HND",
  "HKG",
  "PVG",
  "CDG",
  "AMS",
  "DFW",
  "CAN",
  "FRA",
  "IST",
  "DEL",
  "CGK",
  "SIN",
  "ICN",
  "DEN",
  "BKK",
  "JFK",
  "KUL",
  "SFO",
  "MAD",
  "CTU",
  "LAS",
  "BCN",
  "BOM",
  "YYZ",
  "SEA",
  "CLT",
  "LGW",
  "SZX",
  "TPE",
  "MEX",
  "KMG",
  "MUC",
  "MCO",
  "MIA",
  "PHX",
  "SYD",
  "EWR",
  "MNL",
  "SHA",
  "XIY",
  "FCO",
  "IAH",
  "NRT",
  "SVO",
  "CKG",
  "DMK",
  "MSP",
  "GRU",
  "BOS",
  "SGN",
  "DOH",
  "HGH",
  "DTW",
  "JED",
  "MEL",
  "FLL",
  "MCO",
  "SAW",
  "BOG",
  "DME",
  "CJU",
  "LGA",
  "PHL",
  "DUB",
  "ZRH",
  "CPH",
  "KIX",
  "PMI",
  "MAN",
  "OSL",
  "LIS",
  "ARN",
  "BWI",
  "AYT",
  "STN",
  "NKG",
  "GMP",
  "BLR",
  "RUH",
  "BRU",
  "DUS",
  "XMN",
  "VIE",
  "CGO",
  "SLC",
  "YVR",
  "DCA",
  "CSX",
  "AUH",
  "CUN",
  "FUK",
  "TAO",
  "BNE",
  "WUH",
]

export const CHART_BAR_DATA = [
  {
    dataKey: "economy",
    fill: "#DD0000",
    title: "Economy",
    radius: [0, 0, 3, 3],
  },
  {
    dataKey: "premiumEconomy",
    fill: "#FC9E0A",
    title: "Premium Economy",
    radius: [0, 0, 0, 0],
  },
  {
    dataKey: "business",
    fill: "#B39E69",
    title: "Business",
    radius: [0, 0, 0, 0],
  },
  { dataKey: "first", fill: "#796F5A", title: "First", radius: [3, 3, 0, 0] },
]

export const MONTH_NAMES = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
]

export const PROGRAMS_OPTIONS = [
  { label: "All", value: "All" },
  { label: "Qantas", value: "QF" },
  { label: "Velocity", value: "VA" },
  {
    label: "American Airlines",
    value: "AA",
  },
]

export const STOPS_OPTIONS = [
  {
    label: "Any",
    value: "1",
  },
  {
    label: "Direct",
    value: "2",
  },
  {
    label: "1",
    value: "3",
  },
  {
    label: "2+",
    value: "4",
  },
]
