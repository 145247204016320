import { useState, useEffect, useRef } from "react"
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react"
import Map, { Layer, Popup, Source } from "react-map-gl"
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js"

import { COLORS } from "../../constants/constants"
import Footer from "../Footer"
import AlertComponent from "./components/AlertComponent"

import "mapbox-gl/dist/mapbox-gl.css"
import MarkerComponent from "./components/MarkerComponent"
import { useQuery } from "@tanstack/react-query"
import { getPmaxRoutes } from "../../services/api"
import { useAuthContext } from "../../services/auth"
import Head from "./components/Head"
import { useTranslation } from "react-i18next"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default // eslint-disable-line

const normalizeLng = (lng) => ((((lng + 180) % 360) + 360) % 360) - 180

const getDestinationMarkers = ({ pmaxRoutesQuery, marker }) => {
  return (
    pmaxRoutesQuery.data.routes
      .find((route) => route.origin.id === marker.id)
      ?.destinations.map((destination) => ({
        id: destination.id,
        name: destination.name,
        city: destination.city,
        latitude: parseFloat(destination.city_latitude),
        longitude: parseFloat(destination.city_longitude),
        type: "destination",
      })) || []
  )
}

const AlertsWithMap = () => {
  const { t } = useTranslation()
  const { user } = useAuthContext()
  const [isLargeScreen] = useMediaQuery("(min-width: 1280px)")
  const mapRef = useRef(null)

  const [markers, setMarkers] = useState(null)
  const [originMarkers, setOriginMarkers] = useState(null)
  const [originMarker, setOriginMarker] = useState(null)
  const [destinationMarker, setDestinationMarker] = useState(null)
  const [lineData, setLineData] = useState(null)
  const [hoveredMarker, setHoveredMarker] = useState(null)
  const [viewState, setViewState] = useState({
    longitude: 151.2099,
    latitude: -33.865143,
    zoom: 0,
  })

  const currentDate = new Date()

  const route = {
    user: user?.id,
    origin: originMarker,
    destination: destinationMarker,
    startDate: currentDate,
    endDate: new Date(currentDate.setMonth(currentDate.getMonth() + 1)),
    flightClasses: ["Economy", "PremiumEconomy"],
    pmaxValue: 1,
    isDirect: true,
  }

  const pmaxRoutesQuery = useQuery({
    queryKey: ["pmaxRoutes"],
    queryFn: getPmaxRoutes,
    initialData: [],
  })

  useEffect(() => {
    if (pmaxRoutesQuery.data?.routes?.length) {
      const originMarkers = pmaxRoutesQuery.data.routes.map((route) => ({
        id: route.origin.id,
        name: route.origin.name,
        city: route.origin.city,
        latitude: parseFloat(route.origin.city_latitude),
        longitude: parseFloat(route.origin.city_longitude),
        type: "origin",
      }))

      setOriginMarkers(originMarkers)
      setMarkers(originMarkers)
    }
  }, [pmaxRoutesQuery.data])

  useEffect(() => {
    if (!!originMarker) {
      const data = {
        type: "FeatureCollection",
        features: markers
          .filter((marker) => marker.type !== "origin")
          .map((destination) => {
            let originLng = originMarker.longitude
            let destLng = destination.longitude

            // Adjust longitude for shortest path
            if (Math.abs(destLng - originLng) > 180) {
              destLng =
                normalizeLng(destLng) > normalizeLng(originLng)
                  ? destLng - 360
                  : destLng + 360
            }

            // let coordinates = generateArc(originMarker, {
            //   longitude: destLng,
            //   latitude: destination.latitude,
            // })

            return {
              type: "Feature",
              properties: {
                color:
                  destinationMarker && destination.id === destinationMarker.id
                    ? "#090A0B"
                    : "#A0A3AB",
              },
              geometry: {
                type: "LineString",
                coordinates: [
                  [originMarker.longitude, originMarker.latitude],
                  [destLng, destination.latitude],
                ],
              },
            }
          }),
      }

      setLineData(data)
    }
  }, [originMarker, destinationMarker, markers])

  // Handle Marker Click
  const handleMarkerClick = (marker) => {
    if (marker.type === "origin") {
      if (!!originMarker) {
        setOriginMarker(null)
        setDestinationMarker(null)
        setLineData(null)
        setMarkers(originMarkers)

        if (/Mobi|Android/i.test(navigator.userAgent)) {
          setHoveredMarker(null)
        }
      } else {
        setOriginMarker(marker)
        if (/Mobi|Android/i.test(navigator.userAgent)) {
          setHoveredMarker(marker)
        }

        mapRef.current?.flyTo({
          center: [marker.longitude, marker.latitude],
          duration: 500,
          essential: true,
          zoom: isLargeScreen ? 0.3 : 0.2,
        })

        const destinationMarkers = getDestinationMarkers({
          pmaxRoutesQuery,
          marker,
        })

        destinationMarkers.push(marker)
        setMarkers(destinationMarkers)
      }
    } else {
      if (marker.id === destinationMarker?.id) {
        const makeOrigin = originMarkers.find((originMarker) => {
          return originMarker.id === destinationMarker.id
        })

        if (makeOrigin) {
          setOriginMarker(makeOrigin)

          mapRef.current?.flyTo({
            center: [makeOrigin.longitude, makeOrigin.latitude],
            duration: 500,
            essential: true,
            zoom: isLargeScreen ? 0.3 : 0.2,
          })

          const destinationMarkers = getDestinationMarkers({
            pmaxRoutesQuery,
            marker,
          })

          destinationMarkers.push(makeOrigin)
          setMarkers(destinationMarkers)

          if (destinationMarkers.length === 2) {
            setDestinationMarker(destinationMarkers[0])
          } else {
            setDestinationMarker(null)
          }
        }
      } else {
        setDestinationMarker(marker)

        if (/Mobi|Android/i.test(navigator.userAgent)) {
          setHoveredMarker(marker)
        }
      }
    }
  }

  const handleMarkerHover = (marker) => {
    setHoveredMarker(marker)
  }

  return (
    <Flex
      bg={COLORS.grey}
      flexGrow={1}
      minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box mb={{ base: 6, xl: 9 }}>
        <Head />

        <Flex
          flexDirection={{ base: "column", xl: "row" }}
          borderRadius={"12px"}
          boxShadow={{ base: "none", xl: "0px 1px 3px 1px rgba(0, 0, 0, 0.1)" }}
          backgroundColor={{ base: COLORS.grey, xl: COLORS.white }}
          px={{ base: 3, xl: 0 }}
        >
          <Flex
            width={{ base: "100%", xl: "98%" }}
            height={{
              base: "375px",
              sm: "480px",
              md: "520px",
              lg: "560px",
              xl: "620px",
            }}
            alignSelf={"flex-end"}
            borderRadius={{ base: "12px", xl: 0 }}
            boxShadow={{
              base: "0px 1px 3px 1px rgba(0, 0, 0, 0.1)",
              xl: "none",
            }}
            mb={{ base: 6, xl: 0 }}
            position={"relative"}
          >
            <Map
              {...viewState}
              ref={mapRef}
              mapboxAccessToken={mapboxgl.accessToken}
              onMove={(evt) => setViewState(evt.viewState)}
              mapStyle="mapbox://styles/alfhijasehfinqwrj/cm2y48rre00pq01pi8axm0rq0"
              minZoom={0}
              doubleClickZoom={false}
              style={{
                width: "100%",
                height: "100%",
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
                borderTopRightRadius: isLargeScreen ? 0 : "12px",
                borderBottomRightRadius: isLargeScreen ? 0 : "12px",
              }}
            >
              {markers?.map((marker) => (
                <MarkerComponent
                  key={marker.id}
                  marker={marker}
                  onClick={handleMarkerClick}
                  onHover={handleMarkerHover}
                  isActive={
                    marker.id === originMarker?.id ||
                    marker.id === destinationMarker?.id
                  }
                />
              ))}

              {hoveredMarker && (
                <Popup
                  latitude={hoveredMarker.latitude}
                  longitude={hoveredMarker.longitude}
                  closeButton={false}
                  closeOnClick={false}
                  anchor="bottom"
                  offset={[0, -20]}
                >
                  <Text>{hoveredMarker.name}</Text>
                </Popup>
              )}

              {lineData && (
                <Source id="lines" type="geojson" data={lineData}>
                  <Layer
                    id="dashed-line"
                    type="line"
                    paint={{
                      "line-color": ["get", "color"],
                      "line-width": 1.8,
                      "line-dasharray": [0.8, 0.8],
                    }}
                  />
                </Source>
              )}
            </Map>
          </Flex>

          <Flex
            flex={{ base: "0 1 100%", xl: "0 1 48%" }}
            boxShadow="0px 2px 16px 2px rgba(0, 0, 0, 0.1)"
            zIndex={999}
            borderTopRightRadius={"12px"}
            borderBottomRightRadius={"12px"}
            borderTopLeftRadius={{ base: "12px", xl: 0 }}
            borderBottomLeftRadius={{ base: "12px", xl: 0 }}
            mb={{ base: 9, xl: 0 }}
          >
            {originMarker && destinationMarker ? (
              <AlertComponent route={route} user={user} />
            ) : (
              <Flex
                textAlign={"left"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                gap={6}
                p={"24px 24px 36px"}
                backgroundColor={COLORS.white}
                borderTopRightRadius={"12px"}
                borderBottomRightRadius={"12px"}
                borderTopLeftRadius={{ base: "12px", xl: 0 }}
                borderBottomLeftRadius={{ base: "12px", xl: 0 }}
              >
                <Box>
                  <Text
                    mb={6}
                    color={COLORS.black}
                    fontSize={"2xl"}
                    fontWeight={700}
                  >
                    Qantas - Hourly Reward Seat Alerts
                  </Text>
                  <Text fontSize={"sm"}>
                    Use the map to find destinations from your preferred
                    departure airport. Setup alerts that check availability
                    hourly. Not all Qantas routes may be covered and not all
                    routes and aircraft may accommodate all cabin classes.
                    Available to Monthly Max and Annual subscribers.
                  </Text>
                </Box>

                <Box>
                  <Text textColor={COLORS.black} lineHeight={"16px"}>
                    <Text
                      as={"span"}
                      fontWeight={700}
                      textTransform={"uppercase"}
                      fontSize={"10px"}
                    >
                      Note:
                    </Text>{" "}
                    <Text as={"span"} fontWeight={500} fontSize={"10px"}>
                      {t("map.alertComponent.note")}
                    </Text>
                  </Text>
                </Box>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>

      <Footer />
    </Flex>
  )
}

export default AlertsWithMap
