import React, { useRef, useState } from "react"
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputGroup,
  Text,
  Flex,
} from "@chakra-ui/react"
import { COLORS } from "../../../constants/constants"

const CustomNumberInput = ({ value, onChange, min, max, text }) => {
  const [isFocused, setIsFocused] = useState(false) // State to track focus
  const numberInputRef = useRef(null)

  const handlePassengersClick = () => {
    if (numberInputRef.current) {
      numberInputRef.current.focus()
    }
  }

  return (
    <Flex>
      <Flex
        border={"1px solid"}
        borderColor={isFocused ? "#3182ce" : "#E2E8F0"} // Change border color based on focus
        borderRight={"none"}
        borderRadius={"6px 0 0 6px"}
        paddingLeft={3}
        alignItems={"center"}
        color={COLORS.secondary}
        flex={"1 1 100%"}
        onClick={handlePassengersClick}
        cursor="pointer"
        transitionProperty="common"
        transitionDuration="normal"
      >
        <Text fontWeight={600}>{text}</Text>
      </Flex>
      <InputGroup width={"60px"}>
        <NumberInput
          min={min}
          max={max}
          value={value}
          onChange={(valueString) => onChange(Number(valueString))}
        >
          <NumberInputField
            ref={numberInputRef}
            padding="0"
            border={"1px solid #E2E8F0"}
            borderLeft={"none"}
            borderRadius={"0 6px 6px 0"}
            boxShadow={"none!important"}
            _focus={{
              borderColor: "#3182ce", // Input field already has this focus color
            }}
            onFocus={() => setIsFocused(true)} // Set focus state
            onBlur={() => setIsFocused(false)} // Reset focus state
          />
          <NumberInputStepper width={"30px"}>
            <NumberIncrementStepper border={"none"} />
            <NumberDecrementStepper border={"none"} />
          </NumberInputStepper>
        </NumberInput>
      </InputGroup>
    </Flex>
  )
}

export default CustomNumberInput
