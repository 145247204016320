import { memo } from "react"
import { Box } from "@chakra-ui/react"
import { Marker } from "react-map-gl"
import { COLORS } from "../../../constants/constants"

const MarkerComponent = memo(({ marker, onClick, isActive, onHover }) => {
  const handleMouseEnter = () => {
    if (!/Mobi|Android/i.test(navigator.userAgent)) {
      onHover(marker)
    }
  }

  const handleMouseLeave = () => {
    onHover(null)
  }

  const handleMarkerClick = () => {
    onClick(marker)
  }

  return (
    <Marker
      key={marker.id}
      latitude={marker.latitude}
      longitude={marker.longitude}
    >
      <Box
        as="span"
        role="img"
        aria-label={marker.city}
        fontSize="24px"
        cursor={"pointer"}
        onClick={handleMarkerClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            width: "14px",
            height: "14px",
            border: `2px solid ${COLORS.white}`,
            borderRadius: "50%",
            backgroundColor: COLORS.red,
            boxShadow: isActive
              ? "0px 0px 8px 4px rgba(221, 0, 0, 0.6)"
              : "none",
          }}
        ></div>
      </Box>
    </Marker>
  )
})

export default MarkerComponent
