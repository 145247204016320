import { Flex, Heading, Text } from "@chakra-ui/react"
import { COLORS } from "../../../constants/constants"
import { useTranslation } from "react-i18next"

const Head = () => {
  const { t } = useTranslation()

  return (
    <Flex flexDirection={"column"} gap={2} pb={5} px={{ base: 4, lg: 0 }}>
      <Heading
        as="h1"
        align="left"
        color={COLORS.black}
        fontSize={{ base: "xl", lg: "2xl" }}
        lineHeight={"30px"}
      >
        {t("mapView.title")}
      </Heading>
      <Text
        align="left"
        color={COLORS.black}
        fontSize={{ base: "small", lg: "sm" }}
      >
        {t("mapView.description")}
      </Text>
    </Flex>
  )
}

export default Head
