import FlightsTable from "./FlightsTable/FlightsTable"

const AustralianFlightDetail = ({
  isFetching,
  flights,
  user,
  count,
  orderBy,
  setOrderBy,
  descending,
  setDescending,
  originRegionName,
  destinationRegionName,
}) => {
  return (
    <FlightsTable
      isFetching={isFetching}
      flights={flights}
      user={user}
      count={count}
      orderBy={orderBy}
      descending={descending}
      setOrderBy={setOrderBy}
      setDescending={setDescending}
      originRegionName={originRegionName}
      destinationRegionName={destinationRegionName}
    />
  )
}

export default AustralianFlightDetail
