import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Heading,
  Button,
  Text,
  useToast,
  Stack,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Checkbox,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Switch,
} from "@chakra-ui/react"
import { useForm, Controller } from "react-hook-form"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { format, addDays } from "date-fns"
import invert from "lodash/invert"

import { createPmaxAlert } from "../../../services/api"

import bellImage from "../../../img/bell.svg"
import airplaneImage from "../../../img/alerts_map_airplane.svg"
import AlertDatePicker from "../../AlertDatePicker"
import { COLORS, SUBSCRIPTION } from "../../../constants/constants"
import CustomNumberInput from "./CustomNumberInput"

const flightClassesMapping = {
  Economy: "economy",
  PremiumEconomy: "premiumEconomy",
  Business: "business",
  First: "first",
}

const labelFlightClassMapping = invert(flightClassesMapping)

const AlertComponent = ({ route, user }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [fromDate, setFromDate] = useState(new Date(route.startDate))
  const [toDate, setToDate] = useState(addDays(new Date(route.endDate), 1))

  const { handleSubmit, control, errors } = useForm({
    values: {
      origin: route.origin,
      pmaxValue: route.pmaxValue,
      economy: route.flightClasses.some(
        (flightClass) => flightClass === "Economy"
      ),
      premiumEconomy: route.flightClasses.some(
        (flightClass) => flightClass === "PremiumEconomy"
      ),
      business: route.flightClasses.some(
        (flightClass) => flightClass === "Business"
      ),
      first: route.flightClasses.some((flightClass) => flightClass === "First"),
      status: route.status || "Pending",
      isDirect: route.isDirect,
    },
  })

  const { mutateAsync: createAlertMutation } = useMutation({
    mutationFn: createPmaxAlert,
    onSuccess: () => queryClient.invalidateQueries(["pmaxAlerts"]),
  })

  const onSubmit = async (data) => {
    if (
      !user ||
      user.subscription === SUBSCRIPTION.FREE ||
      user.subscription === SUBSCRIPTION.MONTHLY_LITE
    ) {
      onOpen()
      return
    }

    const formattedData = {
      origin: route.origin.id,
      destination: route.destination.id,
      start_date: format(fromDate, "yyyy-MM-dd"),
      end_date: format(toDate, "yyyy-MM-dd"),
      flight_classes: Object.keys(labelFlightClassMapping)
        .filter((key) => data[key])
        .map((key) => labelFlightClassMapping[key]),
      pmax_value: data.pmaxValue,
      isDirect: data.isDirect,
    }

    try {
      if (route.id) {
        // await updateAlertMutation({ id: route.id, ...formattedData });
      } else {
        await createAlertMutation(formattedData)
      }
      toast({
        position: "bottom-right",
        title: t("alertRouteModal.toastSuccess"),
        status: "success",
      })
    } catch (err) {
      toast({
        position: "bottom-right",
        title: t("alertRouteModal.createToastError"),
        status: "error",
      })
    }
  }

  return (
    <>
      <Flex
        flexDirection={"column"}
        textAlign={"left"}
        width={"100%"}
        borderTopRightRadius={"12px"}
        borderBottomRightRadius={"12px"}
        borderTopLeftRadius={{ base: "12px", xl: 0 }}
        borderBottomLeftRadius={{ base: "12px", xl: 0 }}
      >
        <Flex
          backgroundColor={COLORS.grey}
          flexDirection={"column"}
          p={6}
          borderTopLeftRadius={{ base: "12px", xl: 0 }}
          borderTopRightRadius={{ base: "12px", xl: 0 }}
        >
          <Flex mb="4" alignItems={"center"}>
            <Image
              src={bellImage}
              mr={3}
              alt={`create_reward_flight_alerts_from_${route?.origin.name}_to_${route?.destination.name}`}
            />
            <Heading
              as="h1"
              align="left"
              color={COLORS.black}
              fontSize={"2xl"}
              fontWeight="extrabold"
            >
              {t("alertRouteModal.header")}
            </Heading>
          </Flex>

          <Flex flexDirection={"column"}>
            <Flex gap={3} alignItems={"center"}>
              <div
                style={{
                  borderRadius: "50%",
                  border: `2px solid ${COLORS.secondary}`,
                  width: "16px",
                  height: "16px",
                }}
              />
              <Text fontSize="md" fontWeight={700}>
                {route?.origin.city}
              </Text>
            </Flex>

            <Flex>
              <Image src={airplaneImage} width="16px" height="36px" mr="2px" />
            </Flex>

            <Flex gap={3} alignItems={"center"}>
              <div
                style={{
                  borderRadius: "50%",
                  border: `2px solid ${COLORS.secondary}`,
                  width: "16px",
                  height: "16px",
                }}
              />
              <Text fontSize="md" fontWeight={700}>
                {route?.destination.city}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDirection={"column"}
          justifyContent={"space-between"}
          backgroundColor={COLORS.white}
          p={6}
          pb={9}
          height={"100%"}
          borderBottomLeftRadius={{ base: "12px", xl: 0 }}
          borderBottomRightRadius={{ base: "12px", xl: 0 }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={3} direction="row" mb={4}>
              <Box flex={"0 1 50%"} className={"alerts-date-picker-wrapper"}>
                <Text mb={1} fontSize={"xs"} fontWeight={600}>
                  From:
                </Text>

                <AlertDatePicker
                  date={fromDate}
                  toDate={toDate}
                  startDate={fromDate}
                  minDate={new Date()}
                  handleDatePickerChange={(date) => {
                    setFromDate(date)
                    if (new Date(toDate) < date) {
                      setToDate(date)
                    }
                  }}
                  selectsStart={true}
                />
              </Box>

              <Box flex={"0 1 50%"} className={"alerts-date-picker-wrapper"}>
                <Text mb={1} fontSize={"xs"} fontWeight={600}>
                  To:
                </Text>

                <AlertDatePicker
                  date={toDate}
                  toDate={toDate}
                  startDate={fromDate}
                  minDate={fromDate}
                  handleDatePickerChange={(date) => {
                    setToDate(date)
                  }}
                  selectsEnd={true}
                />
              </Box>
            </Stack>

            <Stack mb={4}>
              <FormControl isInvalid={!!errors?.pmaxValue}>
                <Controller
                  name="pmaxValue"
                  control={control}
                  rules={{
                    required: "Pmax value is required",
                    min: { value: 1, message: "Minimum value is 1" },
                    max: { value: 5, message: "Maximum value is 4" },
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <CustomNumberInput
                      min={1}
                      max={4}
                      value={value}
                      onChange={onChange}
                      text="Passengers:"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors?.pmaxValue?.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack gap={3} direction="row">
              <Flex flexDirection={"column"} w="50%" gap={3}>
                <FormControl isInvalid={!!errors?.economy}>
                  <Controller
                    control={control}
                    name="economy"
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <Checkbox
                          onChange={onChange}
                          ref={ref}
                          isChecked={value}
                          colorScheme={"red"}
                        >
                          <Text
                            fontSize="sm"
                            fontWeight="semibold"
                            whiteSpace={"nowrap"}
                          >
                            Economy
                          </Text>
                        </Checkbox>
                      )
                    }}
                  />
                  <FormErrorMessage>
                    {errors?.economy?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors?.business}>
                  <Controller
                    control={control}
                    name="business"
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <Checkbox
                          onChange={onChange}
                          ref={ref}
                          isChecked={value}
                          colorScheme={"red"}
                        >
                          <Text
                            fontSize="sm"
                            fontWeight="semibold"
                            whiteSpace={"nowrap"}
                          >
                            Business
                          </Text>
                        </Checkbox>
                      )
                    }}
                  />
                  <FormErrorMessage>
                    {errors?.business?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex flexDirection={"column"} w={"50%"} gap={3}>
                <FormControl isInvalid={!!errors?.premiumEconomy}>
                  <Controller
                    control={control}
                    name="premiumEconomy"
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <Checkbox
                          onChange={onChange}
                          ref={ref}
                          isChecked={value}
                          colorScheme={"red"}
                        >
                          <Text
                            fontSize="sm"
                            fontWeight="semibold"
                            whiteSpace={"nowrap"}
                          >
                            Premium Economy
                          </Text>
                        </Checkbox>
                      )
                    }}
                  />
                  <FormErrorMessage>
                    {errors?.premiumEconomy?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors?.first}>
                  <Controller
                    control={control}
                    name="first"
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <Checkbox
                          onChange={onChange}
                          ref={ref}
                          isChecked={value}
                          colorScheme={"red"}
                        >
                          <Text
                            fontSize="sm"
                            fontWeight="semibold"
                            whiteSpace={"nowrap"}
                          >
                            First Class
                          </Text>
                        </Checkbox>
                      )
                    }}
                  />
                  <FormErrorMessage>{errors?.first?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </Stack>

            <Flex backgroundColor={COLORS.grey} px={4} py={2} mt={4}>
              <FormControl isInvalid={!!errors?.isDirect}>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Box>
                    <Text fontWeight={700}>Direct flights only</Text>
                    <Text fontWeight={600} fontSize={"12px"}>
                      If unselected, alerts may include connections.
                    </Text>
                  </Box>
                  <Controller
                    control={control}
                    name="isDirect"
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <Switch
                          onChange={onChange}
                          ref={ref}
                          isChecked={value}
                          colorScheme={"red"}
                        />
                      )
                    }}
                  />
                </Flex>
                <FormErrorMessage>{errors?.isDirect?.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            <Button
              type="submit"
              w={"100%"}
              backgroundColor={COLORS.red}
              color={COLORS.white}
              borderRadius={8}
              boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.24)"}
              textTransform={"uppercase"}
              mt={6}
            >
              {t("alertRouteModal.createButton")}
            </Button>
          </form>

          <Flex gap={1}>
            <Text textColor={COLORS.black} lineHeight={"16px"}>
              <Text
                as={"span"}
                fontWeight={700}
                textTransform={"uppercase"}
                fontSize={"10px"}
              >
                Note:
              </Text>{" "}
              <Text as={"span"} fontWeight={500} fontSize={"10px"}>
                {t("map.alertComponent.note")}
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Subscription Required</ModalHeader>
          <ModalBody>
            <Text>
              Only the users with Monthly Max or Annual subscriptions can create
              alerts here.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              backgroundColor={COLORS.red}
              color={COLORS.white}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AlertComponent
